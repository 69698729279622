import React, {Component} from 'react';
import axios from 'axios';
import apiUrl from '../../config/config';

class AddFindingsTab extends Component {

  constructor() {
    super();
    this.state = {
      findings:"",
      userid:''
    };
     this.generateReport = this.generateReport.bind(this);
  }

  onSubmit = e => {
    e.preventDefault();

    const settings = {
      userid:this.state.userid,
      findings:this.state.findings
    };

    //console.log('settings',settings);
    axios
      .post(apiUrl+'api/common/updateFindings',settings)
      .then(res => {
          
          this.generateReport();
          setTimeout(function() { window.location.reload(false); }, 500);

      })
      .catch(err => {
          console.log(err); 
      });
  }  

  componentDidMount(){
    var userid = this.props.userid;
    this.setState({ userid: userid });
    axios
      .get(apiUrl+'api/common/getPatientFindings?userid='+userid)
      .then(res => {  
        if(res.data.findings){
          this.setState({
            findings:res.data.findings
          })
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChange = e => {
    var err = this.state.errors;
    this.setState({ 'findings': e.target.value });
  }
  generateReport() {
        const userid = this.props.userid;
        //e.currentTarget.innerHTML = "PLease Wait...";
        //
        alert('Regenerating Report');
        axios

            .get('https://web.mybiocalculus.com/html/welcome/new/' + userid)
            .then(res => {
              
            })
            .catch(err => {

            });
    }
        
  render() {
    var userid = this.props.userid;
    var databaseid = this.props.databaseid.replace(/:/g, '_');
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-default card-view">
            <div className="panel-wrapper collapse in">
              <div className="panel-body">
                <p>Please Mention Findings</p>
                <br/>
                <form name="General_addfindings_form" onSubmit={this.onSubmit}>
                  <textarea 
                    id="userFindingsInput" 
                    name="userFindingsInput" 
                    
                    value={this.state.findings}
                    onChange={this.onChange}
                  />
                  { this.props.reportStatus <=10 &&
                    <button type="submit" name="submit" className="btn btn-primary btn-form display-4 generalsetting">SUBMIT</button>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  }
}

export default AddFindingsTab;