import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import apiUrl from "../../config/config";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

class VerifierSelectedBeats extends Component {
  constructor() {
    super();
    this.state = {
      imagess: {},

      datasets: {},
      dblink: "",
      selectedClass: "",
      selectedSub: "allImage",
      brkImg: 0,
      propsFlag: 0,
      filterFlag: 0,
    };
    this.selectImage = this.selectImage.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.recategorizeImg = this.recategorizeImg.bind(this);
  }
  componentDidMount() {
    window.userid = this.props.userid;
    window.selectAll = 0;
    window.index = 0;
    this.setState({
      dblink: this.props.databaseid,
    });

    // this.filter1New(window.filterId2);
     this.filternew2({ currentTarget: { id: 'allImage' } });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.state.propsFlag == 0 &&
      Object.keys(nextProps.imagess).length != 0
    ) {
      var imgs = nextProps.imagess;

      var datasets = nextProps.datasets;
      var selectedClass = nextProps.select;
      window.eventclass = selectedClass;
      this.setState({
        imagess: imgs,
      });

      this.setState({
        datasets: datasets,
      });
      this.setState({
        selectedClass: selectedClass,
      });
      //this.filter2(window.filterId2);
      //this.filter2(window.filterId);
      window.filterId2 = Object.keys(imgs)[0];
      this.filter1New(window.filterId2);
      this.setState({ propsFlag: 1 });
    }

    /* let arr3 = Object.keys(this.state.imagess).map((item, i) => Object.assign({}, item, this.state.datasets[i])));

console.log('Merge',arr3);*/
  }
  filter2(selected) {
    //console.log("SELECTED",selected);

    //console.log('Sub filter2',selected);
    this.setState({ brkImg: 0 });
    if (selected == "all") {
      $("#portfolio1").children().show(1000);
      $(".filtersNewMain a").removeClass("active");
      $("." + selected).addClass("active");
      window.eventclass = selected;
    } else {
      $("#portfolio1").children().hide(1000);
      var thisclass = "#portfolio1 ." + selected;
      $("#portfolio1 ." + selected).show(1000);
      // $(".filtersNewMain a").removeClass('active');
      $("." + selected + " a").addClass("active");
      $("." + this.state.selectedSub + " a").addClass("active");
      window.eventclass = selected;
    }
  }

  filter1(e) {
    var selected = e.currentTarget.id;
    //console.log('Sub filter1',selected);
    this.setState({ brkImg: 0 });
    if (selected == "all") {
      $("#portfolio1").children().show(1000);
      $(".filtersNewMain a").removeClass("active");
      $("." + e.currentTarget.id).addClass("active");
      window.eventclass = e.currentTarget.id;
    } else {
      $("#portfolio1").children().hide(1000);
      var thisclass = "#portfolio1 ." + selected;
      $("#portfolio1 ." + selected).show(1000);
      $(".filtersNewMain a").removeClass("active");
      $("." + e.currentTarget.id + " a").addClass("active");
      $("." + this.state.selectedSub + " a").addClass("active");
      window.eventclass = e.currentTarget.id;
    }
  }
  filter1New(selected) {
    console.log("Sub filter1New", selected);
    this.setState({ brkImg: 0 });
    if (selected == "all") {
      $("#portfolio1").children().show(1000);
      $(".filtersNewMain a").removeClass("active");
      $("." + selected).addClass("active");
      window.eventclass = selected;
    } else {
      $("#portfolio1").children().hide(1000);
      var thisclass = "#portfolio1 ." + selected;
      $("#portfolio1 ." + selected).show(1000);
      $(".filtersNewMain a").removeClass("active");
      $("." + selected + " a").addClass("active");
      $("." + this.state.selectedSub + " a").addClass("active");
      window.eventclass = selected;
    }
  }

  filternew(e) {
    var selected = e.currentTarget.id;
    console.log("selected", selected);
    this.setState({ brkImg: 0 });
    this.setState({ selectedSub: "maxImage" });
    var x = document.getElementById("galleryMain");
    var y = document.getElementById("galleryMax");
    var rf = document.getElementById("RF");
    var sa = document.getElementById("SelectA");
    console.log("Select All", sa);
    if (x.style.display === "block") {
      x.style.display = "none";
      y.style.display = "block";
      rf.style.display = "none";
      sa.style.display = "none";
    }
    console.log("Select All", sa);
    $(".filternew a").removeClass("active");
    $("#" + selected + " a").addClass("active");
    /* else {
        x.style.display = "none";
        y.style.display = "block";
      }*/
  }
  filternew2(e) {
    var selected = e.currentTarget.id;
    //console.log('Sub filternew2',selected);
    this.setState({ selectedSub: "allImage" });
    this.setState({ brkImg: 0 });
    var x = document.getElementById("galleryMain");
    var y = document.getElementById("galleryMax");
    var rf = document.getElementById("RF");
    var sa = document.getElementById("SelectA");
    if (x.style.display === "none") {
      x.style.display = "block";
      y.style.display = "none";
      rf.style.display = "block";
      sa.style.display = "block";
    }
    $(".filternew a").removeClass("active");
    $("#" + selected + " a").addClass("active");
  }
  removeFiles(e) {
    if (window.confirm("Do you want to delete the selected strips?")) {
      var checkedArray = [];
      var userid = this.props.userid;
      var self = this;
      var databaseid;
      var folder = window.eventclass;
      console.log(folder);
      $("input:checkbox[name=test]:checked").each(function () {
        checkedArray.push($(this).val());
        this.checked = false;
        var clickedPI = this.id.toString();
        var res = self.splitFn(clickedPI);

        databaseid = res[2];
      });
      if (checkedArray.length > 0) {
        const data = {
          userid: userid,
          databaseid: databaseid,
          checkedArray: checkedArray,
          folder: folder,
        };
        console.log(data);
        axios
          .post(
            "https://web.mybiocalculus.com:5000/api/common/removeFilesReport",
            data
          )
          .then((res) => {
            console.log("Files removed");
            var datasets = [];

            var testArr = {};
            var datArr = {};
            axios
              .get(
                apiUrl +
                  "api/common/patinentmenutree?userid=" +
                  this.props.userid
              )
              .then((res) => {
                datasets = res.data;

                self.getRepos1(this.props.userid, datasets).then((value) => {
                  for (var ij = 0; ij < datasets.length; ij++) {
                    if (value[ij].data[0].length != 0) {
                      var strdat = "";

                      //console.log('testDatasets',value[ij].data);
                      var keys = Object.keys(value[ij].data[0]);

                      for (var ik = 0; ik < keys.length; ik++) {
                        if (testArr[keys[ik]] != undefined) {
                          var strapp =
                            testArr[keys[ik]] +
                            "," +
                            value[ij].data[0][keys[ik]];
                          testArr[keys[ik]] = strapp.split(",");
                        } else {
                          //console.log('testDatasets',value[ij].data[1]);
                          testArr[keys[ik]] = value[ij].data[0][keys[ik]];
                        }
                      }
                    }
                  }

                  this.setState({
                    imagess: testArr,
                  });
                  this.setState({
                    datasets: datArr,
                  });
                  window.filterId2 = Object.keys(testArr)[0];
                  this.filter1New(window.filterId2);
                  alert("File Removed");
                });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
  selectAll(e) {
    var searchDiv = document.getElementById("portfolio1");
    var selBtn = document.getElementById("SA");
    //console.log(searchDiv);
    /* var cds= document.getElementById('currentDataSet');
         var fds = document.getElementById('fullDataSet');*/
    //console.log(fds);

    if (window.selectAll == 0) {
      selBtn.childNodes[0].text = "Deselect All";
      console.log("select all files", searchDiv.childNodes);
      for (var i = 0; i < searchDiv.childNodes.length; i++) {
        if (
          searchDiv.childNodes[i].childNodes[1].className == window.eventclass
        ) {
          searchDiv.childNodes[i].childNodes[1].checked = "checked";
        }
      }
      window.selectAll = 1;
    } else if (window.selectAll == 1) {
      selBtn.childNodes[0].text = "Select All";
      for (var i = 0; i < searchDiv.childNodes.length; i++) {
        if (
          searchDiv.childNodes[i].childNodes[1].className == window.eventclass
        ) {
          searchDiv.childNodes[i].childNodes[1].checked = "";
        }
      }
      window.selectAll = 0;
    }
  }
  splitFn(value) {
    var res = value.split("_");

    var name =
      res[0].substring(0, 4) +
      "-" +
      res[0].substring(4, 6) +
      "-" +
      res[0].substring(6, 8) +
      " :: " +
      res[1].substring(0, 2) +
      ":" +
      res[1].substring(2, 4) +
      ":" +
      res[1].substring(4, 6);
    var name2 =
      res[0].substring(0, 4) +
      "-" +
      res[0].substring(4, 6) +
      "-" +
      res[0].substring(6, 8) +
      " " +
      res[0].substring(8, 10) +
      ":" +
      res[0].substring(10, 12) +
      ":" +
      res[0].substring(12, 14);
    res[0] = name;
    res[2] = name2;

    return res;
  }
  recategorizeImg(e) {
    var checkedArray = [];
    var userid = this.props.userid;
    var databaseid;
    var folder = e.currentTarget.value;

    var self = this;
    var clickedPI_;
    var value1;
    const data = [];
    var i = 0;
    $("input:checkbox[name=test]:checked").each(function () {
      console.log(this.parentNode.style.display);
      this.parentNode.style.display = "none";
      checkedArray.push($(this).val());
      this.checked = false;
      var clickedPI = this.id.toString();
      var res = self.splitFn(clickedPI);

      databaseid = res[2];
      clickedPI = clickedPI.split("_");
      clickedPI_ =
        clickedPI[1].substring(0, 2) +
        ":" +
        clickedPI[1].substring(2, 4) +
        ":" +
        clickedPI[1].substring(4, 6);
      data[i] = {
        userid: userid,
        databaseid: databaseid,
        clickedPI_: clickedPI_,
      };
      i = i + 1;

      window.beatArr.push(clickedPI[2].split(".")[0]);
      value1 = this.value.toString();
    });
    console.log("test");
    console.log(checkedArray);
    if (folder == "un") {
      value1 = "Undiagnosed";
    } else if (folder == "nsr") {
      value1 = "Normal Sinus Rhythm";
    } else if (folder == "sb") {
      value1 = "Sinus Bradycardia";
    } else if (folder == "st") {
      value1 = "Sinus Tachycardia";
    } else if (folder == "pac") {
      value1 = "PAC";
    } else if (folder == "pvc") {
      value1 = "PVC";
    } else if (folder == "vt") {
      value1 = "Ventricular Tachycardia";
    } else if (folder == "af") {
      value1 = "AFib";
    } else if (folder == "sp") {
      value1 = "Sinus Pause";
    } else if (folder == "pa") {
      value1 = "P Absent";
    } else if (folder == "irr") {
      value1 = "Irregular RR";
    } else if (folder == "ipr") {
      value1 = "Irregular PR";
    } else if (folder == "sqt") {
      value1 = "Short QT";
    } else if (folder == "lqt") {
      value1 = "Prolonged QT";
    }
    console.log("hello");

    //self.recategorizeAfterImage(value1)

    if (checkedArray.length > 0) {
      const data = {
        userid: userid,
        databaseid: databaseid,
        checkedArray: checkedArray,
        folder: folder,
      };
      var datasets = [];
      var self = this;
      var testArr = {};
      var datArr = {};
      if (folder != "max_event") {
        console.log("hello", data);
        ///var/www/html/assets/dist/user_records/5f40e5f444e8256962d32c9c/pac/cropped_image
        axios
          .post(
            "https://web.mybiocalculus.com:5000/api/common/reCategorizeReport",
            data
          )
          .then((res) => {
            axios
              .get(
                apiUrl +
                  "api/common/patinentmenutree?userid=" +
                  this.props.userid
              )
              .then((res) => {
                datasets = res.data;

                self.getRepos1(this.props.userid, datasets).then((value) => {
                  for (var ij = 0; ij < datasets.length; ij++) {
                    if (value[ij].data[0].length != 0) {
                      var strdat = "";

                      //console.log('testDatasets',value[ij].data);
                      var keys = Object.keys(value[ij].data[0]);

                      for (var ik = 0; ik < keys.length; ik++) {
                        if (testArr[keys[ik]] != undefined) {
                          var strapp =
                            testArr[keys[ik]] +
                            "," +
                            value[ij].data[0][keys[ik]];
                          testArr[keys[ik]] = strapp.split(",");
                        } else {
                          //console.log('testDatasets',value[ij].data[1]);
                          testArr[keys[ik]] = value[ij].data[0][keys[ik]];
                        }
                      }
                    }
                  }

                  this.setState({
                    imagess: testArr,
                  });
                  this.setState({
                    datasets: datArr,
                  });
                  window.filterId2 = Object.keys(testArr)[0];
                  this.filter1New(window.filterId2);
                  alert("File Moved");
                });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // console.log('hello',data);
        axios
          .post(
            "https://web.mybiocalculus.com:5000/api/common/reCategorizeReportForMax",
            data
          )
          .then((res) => {
            alert("Image Added");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
  selectImage(e) {
    var folder = window.eventclass;
    /*console.log(folder);
        console.log(e.currentTarget.className.split(" ")[3]);*/
    if (e.currentTarget.className.split(" ")[3] == folder) {
      if (e.currentTarget.childNodes[1].checked == true) {
        e.currentTarget.childNodes[1].checked = false;
      } else {
        e.currentTarget.childNodes[1].checked = true;
      }
      console.log(e.currentTarget.childNodes[1].checked);
    } else {
      e.currentTarget.childNodes[1].checked = false;
    }
  }
  generateReport(e) {
    const userid = this.props.userid;
    e.currentTarget.innerHTML = "PLease Wait...";
    //
    axios
      .get(
        "https://web.mybiocalculus.com/html/welcome/generateReportTest/" +
          userid
      )
      .then((res) => {})
      .catch((err) => {});
  }
  async getRepos1(userid, datasets) {
    var ops = [];

    for (var ii = 0; ii < datasets.length; ii++) {
      let op = axios.get(
        "https://web.mybiocalculus.com:3002/api/common/getselectedfolderfiles?userid=" +
          this.props.userid +
          "&databaseid=" +
          datasets[ii]
      );
      ops.push(op);
    }

    let res = await axios.all(ops);

    return res;
  }
  onImageError = () => {
    console.log("erroorrrr");
    this.setState({ brkImg: null });
  };
  render() {
    var rand = Math.floor(Math.random() * 10000);
    let titleFlag = 0;
    return (
      <div
        id="chart_sel_events"
        className="tab-pane fade active in"
        role="tabpanel"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="">
              <div className="panel-wrapper collapse in">
                <div className="panel-body">
                  <h5 className="panel-title txt-light cd">
                    Cardiac Event Detected
                  </h5>
                  <div className="filter-wrap mb-1">
                    <ul id="filters" className="filtersNewMain">
                      {Object.keys(this.state.imagess).map((val, key) => {
                        if (titleFlag == 0) {
                          titleFlag = 1;

                          return (
                            <li
                              key={key}
                              id={val}
                              value={val}
                              className={val}
                              onClick={this.filter1.bind(this)}
                            >
                              <a
                                href="#"
                                data-filter={"." + val}
                                className="active"
                              >
                                {val}
                              </a>
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={key}
                              id={val}
                              value={val}
                              className={val}
                              onClick={this.filter1.bind(this)}
                            >
                              <a href="#" data-filter={"." + val}>
                                {val}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>

                    <hr />
                    <div className="clearfix"></div>
                  </div>
                  <div className="filter-wrap mb-10">
                    <ul id="filters" className="filternew">
                      <React.Fragment>
                        <li
                          id="allImage"
                          value="allImage"
                          onClick={this.filternew2.bind(this)}
                        >
                          <a href="#" className="active">
                            All
                          </a>
                        </li>
                        <li
                          id="maxImage"
                          value="maxImage"
                          onClick={this.filternew.bind(this)}
                        >
                          <a href="#">Max</a>
                        </li>
                      </React.Fragment>
                    </ul>
                  </div>
                  <h5 className="panel-title txt-light rechead">
                    Recategorize To
                  </h5>
                  <div className="row mt-5 mt-5hed">
                    <div className="col-sm-12">
                      <button
                        className="arythmia-btn nsr-btn"
                        value="nsr"
                        onClick={this.recategorizeImg}
                      >
                        NSR
                      </button>
                      <button
                        className="arythmia-btn st-btn"
                        value="st"
                        onClick={this.recategorizeImg}
                      >
                        ST
                      </button>
                      <button
                        className="arythmia-btn sb-btn"
                        value="sb"
                        onClick={this.recategorizeImg}
                      >
                        SB
                      </button>
                      <button
                        className="arythmia-btn pac-btn"
                        value="pac"
                        onClick={this.recategorizeImg}
                      >
                        PAC
                      </button>
                      <button
                        className="arythmia-btn pvc-btn"
                        value="pvc"
                        onClick={this.recategorizeImg}
                      >
                        PVC
                      </button>
                      <button
                        className="arythmia-btn vt-btn"
                        value="vt"
                        onClick={this.recategorizeImg}
                      >
                        VT
                      </button>
                      <button
                        className="arythmia-btn af-btn"
                        value="af"
                        onClick={this.recategorizeImg}
                      >
                        AF
                      </button>
                      <button
                        className="arythmia-btn sp-btn"
                        value="sp"
                        onClick={this.recategorizeImg}
                      >
                        SP
                      </button>
                      <button
                        className="arythmia-btn pa-btn"
                        value="pa"
                        onClick={this.recategorizeImg}
                      >
                        AT
                      </button>
                      <button
                        className="arythmia-btn irr-btn"
                        value="irr"
                        onClick={this.recategorizeImg}
                      >
                        CHB
                      </button>
                      <button
                        className="arythmia-btn ipr-btn"
                        value="ipr"
                        onClick={this.recategorizeImg}
                      >
                        2AVB
                      </button>
                      <button
                        className="arythmia-btn sqt-btn"
                        value="sqt"
                        onClick={this.recategorizeImg}
                      >
                        1AVB
                      </button>
                      <button
                        className="arythmia-btn lqt-btn"
                        value="lqt"
                        onClick={this.recategorizeImg}
                      >
                        LQT
                      </button>
                      <button
                        className="arythmia-btn un-btn"
                        value="un"
                        onClick={this.recategorizeImg}
                      >
                        UN
                      </button>
                      <button
                        className="arythmia-btn un-btn"
                        value="max_event"
                        onClick={this.recategorizeImg}
                      >
                        MAX_Event
                      </button>
                      <ul
                        role="tablist"
                        className="nav nav-pills"
                        id="myTabs_6"
                      >
                        {window.eventclass != "all" && (
                          <li
                            role="presentation"
                            className="pull-right"
                            id="RF"
                          >
                            <a onClick={this.removeFiles.bind(this)}>
                              Remove File
                            </a>
                          </li>
                        )}

                        {window.eventclass != "all" && (
                          <li
                            role="presentation"
                            className="pull-right"
                            id="SelectA"
                          >
                            <a onClick={this.selectAll.bind(this)}>
                              Select All
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div
                    className="gallery-wrap"
                    id="galleryMain"
                    style={{ display: "none" }}
                  >
                    <div className="portfolio-wrap project-gallery">
                      <div
                        id="portfolio1"
                        className="row portf auto-construct  project-gallery"
                      >
                        {Object.keys(this.state.imagess).map((val, key) => {
                          var key1 = key;
                          var datasets = this.state.datasets;

                          return (
                            <React.Fragment key={key}>
                              {this.state.imagess[val].map((value, key) => {
                                //console.log('index',this.state.datasets[val]);
                                /*let temp = datasets[val].find(element=> val.key === val.key)
                                               console.log('temp',temp);*/

                                var tempvalue = this.splitFn(value);
                                return (
                                  <div
                                    key={key}
                                    onDoubleClick={this.updateState}
                                    id={tempvalue[1]}
                                    onClick={this.selectImage}
                                    className={"col-sm-3 item tall " + val}
                                    data-src="dist/img/gallery/mock1.jpg"
                                    data-sub-html=""
                                  >
                                    <p>{tempvalue[0]}</p>
                                    <input
                                      type="checkbox"
                                      id={value}
                                      value={val + "|" + value}
                                      className={val}
                                      name="test"
                                    />
                                    <label htmlFor={value}>
                                      <img
                                        className="img-responsive zoom"
                                        src={
                                          "https://web.mybiocalculus.com/assets/dist/user_records/" +
                                          window.userid +
                                          "/" +
                                          val +
                                          "/cropped_image/" +
                                          tempvalue[2] +
                                          "/" +
                                          value
                                        }
                                        alt="Image description"
                                      />
                                    </label>
                                  </div>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div
                    className="gallery-wrap"
                    id="galleryMax"
                    style={{ display: "none" }}
                  >
                    <div className="portfolio-wrap project-gallery">
                      <div
                        id="portfolio1"
                        className="row portf auto-construct  project-gallery"
                      >
                        <React.Fragment>
                          <div
                            onDoubleClick={this.updateState}
                            id={window.eventclass}
                            onClick={this.selectImage}
                            className={
                              "col-sm-3 item tall " + window.eventclass
                            }
                            data-src="dist/img/gallery/mock1.jpg"
                            data-sub-html=""
                          >
                            <input
                              type="checkbox"
                              id={window.eventclass}
                              value={window.eventclass}
                              className={window.eventclass}
                              name="test"
                            />
                            <label htmlFor={window.eventclass}>
                              {this.state.brkImg == 0 && (
                                <img
                                  className="img-responsive zoom"
                                  onError={this.onImageError}
                                  src={
                                    "https://web.mybiocalculus.com/assets/dist/user_records/" +
                                    window.userid +
                                    "/" +
                                    window.eventclass +
                                    "/cropped_image/max_" +
                                    window.eventclass +
                                    ".png?" +
                                    rand
                                  }
                                  alt="Image description"
                                />
                              )}
                            </label>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifierSelectedBeats;
