import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import Loader from "react-loader-spinner";
import apiUrl from "../../config/config";
import "react-dropdown/style.css";

class Addmodaldisclosure extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showModaldisComplete: false,
      showModalNewdisComplete: false,
      showModaldisSpinnerComplete: false,
      loading: false,
      startProp: "",
      userid: "",
      optionsnewoneand: [],
      optionsnewoneendand: [],
      start_generated: "",
      end_generated: "",
    };
    this.handleCloseModaldisComplete = this.handleCloseModaldisComplete.bind(this);
    this.handleCloseModaldisSpinnerNewComplete = this.handleCloseModaldisSpinnerNewComplete.bind(this);
    this.handleOpenModaldisSpinnerNewComplete = this.handleOpenModaldisSpinnerNewComplete.bind(this);
    this.openGeneratemodalNewdis = this.openGeneratemodalNewdis.bind(this);
  }

  componentDidMount() {
    ReactModal.setAppElement("body");
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userid !== this.props.userid ||
      prevProps.start !== this.props.start ||
      prevProps.isOpen !== this.props.isOpen
    ) {
      this.setState(
        {
          userid: this.props.userid,
          startProp: this.props.start,
        },
        this.updateOptions
      );
    }
  }

  updateOptions = () => {
    const { treemenu, isOpen } = this.props;
    // console.log(treemenu)
    if (isOpen) {
      let optionnewon = [];
      let optionnewendon = [];
      Object.keys(treemenu).forEach((val) => {
        treemenu[val].forEach((defaultValue) => {
          const MainTimeon = defaultValue.date.split("T");
          const timeon = MainTimeon[1].split("+");
          optionnewon.push(`${val} ${timeon[0]}`);
          optionnewendon.push(`${val} ${timeon[0]}`);
        });
      });

      optionnewon.sort();
      optionnewendon.sort((a, b) => b.localeCompare(a));

      this.setState({
        start_generated: optionnewon[0],
        end_generated: optionnewendon[0],
        optionsnewoneand: optionnewon.map((opt) => (
          <option value={opt} key={opt}>
            {opt}
          </option>
        )),
        optionsnewoneendand: optionnewendon.map((opt) => (
          <option value={opt} key={opt}>
            {opt}
          </option>
        )),
        showModaldisComplete: isOpen,
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (
      e.target.name === "start_generated" ||
      e.target.name === "end_generated"
    ) {
      this.setState({ newName: e.target.value });
    }
  };

  handleCloseModaldisComplete() {
    this.setState({ showModaldisComplete: false });
    this.props.onSelectmodaldis();
  }

  handleCloseModaldisSpinnerNewComplete() {
    this.setState({ showModaldisSpinnerComplete: false });
    this.setState({ showModaldisComplete: true }); // Reopen the main modal once the spinner modal is closed
  }

  handleOpenModaldisSpinnerNewComplete() {
    this.setState({ showModaldisSpinnerComplete: true });
  }

  openGeneratemodalNewdis() {
    this.setState({ showModaldisComplete: false });
    this.handleOpenModaldisSpinnerNewComplete();

    const { userid, start_generated, end_generated } = this.state;

    axios
      .get(
        `https://web.mybiocalculus.com/html/welcome/fulldisclosure/?userid=${userid}&start=${start_generated.replace(
          / /g,
          "%20"
        )}&end=${end_generated.replace(/ /g, "%20")}`
      )
      .then((res) => {
        this.handleCloseModaldisSpinnerNewComplete();
      })
      .catch((err) => {
        this.handleCloseModaldisSpinnerNewComplete();
      });
  }

  Download = () => {
    const { userid } = this.state;
    const downloadUrl = `${apiUrl}api/users/download?userid=${userid}`;

    axios({
      url: downloadUrl,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        console.log('response')
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${userid}.pdf`);

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.error("Download Error:", err);
      });
  };

  render() {
    const customStyles = {
      content: {
        background: "transparent",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const customStylesNew = {
      content: {
        top: "38%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        border: "1px solid rgb(204, 204, 204)",
        background: "rgb(255, 255, 255)",
        overflow: "auto",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        height: "auto",
        width: "35%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    };

    return (
      <React.Fragment>
        <ReactModal
          isOpen={this.state.showModaldisSpinnerComplete}
          style={customStylesNew}
          shouldCloseOnOverlayClick={true}
        >
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Loader type="Puff" color="#00BFFF" height={80} width={80} />
            <span
              style={{
                display: "block",
                marginTop: "10px",
                color: "black",
                fontSize: "16px",
              }}
            >
              Generating FD....
            </span>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.showModaldisComplete}
          style={customStylesNew}
          contentLabel="Full Disclosure Modal"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="blog-pop-box"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "white",
              padding: "20px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            <button
              className="pull-right closeBtn"
              onClick={this.handleCloseModaldisComplete}
              style={{
                border: "none",
                background: "transparent",
                fontSize: "24px",
                cursor: "pointer",
                color: "#333",
                alignSelf: "flex-end",
                marginBottom: "10px",
              }}
            >
              &#10006;
            </button>

            <div
              className="fea"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "600px",
                background: "white",
                padding: "20px",
                borderRadius: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ flex: "1", marginRight: "10px" }}>
                  <label
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    Start Time
                  </label>
                  <select
                    name="start_generated"
                    onChange={this.onChange}
                    style={{
                      color: "black",
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {this.state.optionsnewoneand}
                  </select>
                </div>

                <div style={{ flex: "1" }}>
                  <label
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    End Time
                  </label>
                  <select
                    name="end_generated"
                    onChange={this.onChange}
                    style={{
                      color: "black",
                      width: "100%",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {this.state.optionsnewoneendand}
                  </select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <button
                  onClick={this.openGeneratemodalNewdis}
                  style={{
                    border: "none",
                    background: "#2ECD99",
                    fontWeight: "bold",
                    color: "white",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderRadius: "4px",
                  }}
                >
                  Proceed
                </button>
                <button
                  onClick={this.Download}
                  style={{
                    border: "none",
                    background: "#2ECD99",
                    fontWeight: "bold",
                    color: "white",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderRadius: "4px",
                  }}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </React.Fragment>
    );
  }
}

export default Addmodaldisclosure;