import React, {Component} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateUser } from '../../actions/authActions';
import axios from 'axios';
import apiUrl from '../../config/config';
import $ from "jquery";
import './editverifier.css'
import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';

class EditVarifier extends Component {
 
  handleChange = selectedOption => {
    this.setState({process:1})
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };  
  constructor() {
    super();
    this.state = {
        menus : [
            {title:'Doctors List',url:'/doctors-list'},
            {title:'Verifiers List',url:'/verifiers-list'},
            {title:'Patient List',url:'/admin-home'},
        ],
        profileLink : 'admin-profile',
        name: '',
        phone: '',
        email: '',
        gender: 'M',
        address: '',
        password: '',
        password2: '',
        terms: true,
        selectedOption:[],
        patients:[],
        selectedPatients:[],
        oldPatientList : [],
        errors: {},
        id:'',
        process:0
    };
    this.handleChange = this.handleChange.bind(this);
  }

    componentDidMount() {
        var id = this.props.match.params.id;

        axios
            .get(apiUrl+'api/users/verifier?id='+id)
            .then(res => {        
                console.log(res.data);
                this.setState({
                  name: res.data.name,
                  phone: res.data.phone,
                  email: res.data.email,
                  gender: res.data.gender,
                  address: res.data.address,
                  id: res.data._id,
                  selectedPatients: res.data.patients,
                  oldPatientList: res.data.patients
                });
                axios
                  .get(apiUrl+'api/patients/adminpatients')
                  .then(res => {
                    this.setState({ ['patients']: res.data })
                  })
                  .catch(err => {
                    this.setState({ ['patients']: [] })
                  });
                //$('#selectedPatients').val(res.data.patients);
            })
            .catch(err => {
            });
    }

    componentWillReceiveProps(nextProps) { 
        console.log(nextProps);
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors});
        } 
    }

    onChange = e => {
        var err = this.state.errors;
        delete err[e.target.name];
        console.log(e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();
        var patientsList = [];
        this.state.selectedOption.map(function(patient,i) {
            patientsList.push(patient.value);
        })
        const User = {
          id: this.state.id,
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          gender: this.state.gender,
          address: this.state.address,
          password: this.state.password,
          password2: this.state.password2,
          terms: this.state.terms,
          selectedPatients: patientsList,
          oldPatientList: this.state.oldPatientList
        };
        this.props.updateUser(User,this.props.history,'/verifiers-list');
    }

  render() {
    const { errors,patients } = this.state;

    let options = [];
    patients.map(function(patient,i) {
        if(this.state.selectedPatients.includes(patient._id) && this.state.process==0) {
            this.state.selectedOption.push({
                value: patient._id, label: patient.name
            })
        }
        options.push({
            value: patient._id, label: patient.name
        })
    },this)
    
    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
            <TopNav profileLink={this.state.profileLink}/>
            <SideBar menus={this.state.menus}/>
            <div className="right-sidebar-backdrop"></div>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row heading-bg">
                        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                          <h5 className="txt-dark">Edit Verifier</h5>
                        </div>
                        <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                          <ol className="breadcrumb">
                            <li><a href="index.html">Home </a></li>
                            <li><a href="#"><span>Verifier</span></a></li>
                          </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel panel-default card-view">
                                <div className="panel-heading">
                                    <div className="clearfix">
                                        <a href="/verifiers-list">
                                            <button type="button" className="btn btn-success"><i className="fa fa-arrow-left"></i> Verifiers List</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="panel-wrapper collapse in">

                                    <div className="panel-body">
                                        <div className="table-wrap">
                                            <div className="table-responsive col-md-12">
                                                <form method="post" id="signup_form" role="form" onSubmit={this.onSubmit}>
                                                    <div className="form-group">
                                                        <label className="control-label mb-10" htmlFor="exampleInputName_1">Username</label>
                                                        <input 
                                                            type="text" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.name
                                                            })} 
                                                            name="name" 
                                                            required="" 
                                                            id="name" 
                                                            onChange={this.onChange}
                                                            value={this.state.name}
                                                            placeholder="Username" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-label mb-10" htmlFor="exampleInputName_11">PhoneNumber</label>
                                                        <input 
                                                            type="number" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.phone
                                                            })} 
                                                            name="phone" 
                                                            required="" 
                                                            id="phone" 
                                                            onChange={this.onChange}
                                                            value={this.state.phone}
                                                            placeholder="Phone" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-label mb-10" htmlFor="exampleInputEmail_2">Email address</label>
                                                        <input 
                                                            type="text" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.email
                                                            })} 
                                                            name="email" 
                                                            required="" 
                                                            id="email" 
                                                            onChange={this.onChange}
                                                            value={this.state.email}
                                                            placeholder="Enter email" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="pull-left control-label " htmlFor="exampleInputpwd_3">select Patients</label>
                                                        <br/>                                                  
                                                        <Select
                                                        
                                                            isMulti
                                                            value={this.state.selectedOption}
                                                            onChange={this.handleChange}
                                                            options={options}
                                                            
                                                        />
                                                    </div>
                                                    <div className="form-group mb-30">
                                                        <label className="control-label mb-10 text-left">Gender</label>
                                                        <div className="radio radio-primary">
                                                            <input 
                                                                type="radio" 
                                                                name="gender" 
                                                                id="radio1" 
                                                                value="M" 
                                                                checked={this.state.gender === "M"} 
                                                                onChange={this.onChange}
                                                            />
                                                            <label htmlFor="radio1">
                                                                M
                                                            </label>
                                                        </div>
                                                        <div className="radio radio-primary">
                                                            <input 
                                                                type="radio" 
                                                                name="gender" 
                                                                id="radio2" 
                                                                value="F" 
                                                                checked={this.state.gender === "F"}
                                                                onChange={this.onChange}
                                                            />
                                                            <label htmlFor="radio2">
                                                                F
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-label mb-10" htmlFor="exampleInputName_11">Address</label>
                                                        <textarea 
                                                            className="form-control" 
                                                            name="address" 
                                                            id="address"
                                                            onChange={this.onChange}
                                                            value={this.state.address}
                                                        > 
                                                        </textarea>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_2">Password</label>
                                                        <input 
                                                            type="password" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.password
                                                            })} 
                                                            name="password" 
                                                            data-minlength="6" 
                                                            required="" 
                                                            id="password" 
                                                            placeholder="Enter password" 
                                                            onChange={this.onChange}
                                                            value={this.state.password}
                                                        />
                                                        <div className="help-block">Minimum of 6 characters</div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_3">Confirm Password</label>
                                                        <input 
                                                            type="password" 
                                                            className={classnames('form-control',{
                                                                'errors' : errors.password2
                                                            })} 
                                                            required="" 
                                                            name="password2"
                                                            id="password2" 
                                                            placeholder="Enter password" 
                                                            onChange={this.onChange}
                                                            value={this.state.password2}
                                                        />

                                                    </div>
                                                    <div className="registrationFormAlert" id="divCheckPasswordMatch" ></div>
                                                    <div 
                                                        className={classnames('mb-30 error-container',{
                                                                        'error-container-show' : errors.exists
                                                                    })}
                                                    >
                                                        <h6 className="text-center nonecase-font txt-red">{errors.exists}</h6>
                                                    </div>
                                                    <div className="form-group text-right">
                                                        <div className="btnshow">
                                                            <button type="submit" className="btn btn-info btn-success btn-rounded">Update</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

EditVarifier.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { updateUser })(withRouter(EditVarifier));