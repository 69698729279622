import React, { Component } from "react";
import ReactModal from "react-modal";
import apiUrl from "../../config/config";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Loader from "react-loader-spinner";

class AddModal extends Component {
  // ... skip
  constructor() {
    super();
    this.state = {
      showModal: false,
      showModalNew: false,
      showModalSpinner: false,
      clickdata: "",
      startProp: "",
      userid: "",
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalNew = this.handleCloseModalNew.bind(this);
    this.handleCloseModalSpinnerNew =
      this.handleCloseModalSpinnerNew.bind(this);
  }
  componentDidMount() {
    ReactModal.setAppElement("body");
    window.analyzeLink = "";
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    this.state.clickdata = nextProps.clickdb;
    this.state.showModal = nextProps.isOpen;
    this.state.userid = nextProps.userid;
    this.state.startProp = nextProps.start;
  }
  handleCloseModal() {
    this.setState({ showModal: false });
    this.props.onResetModal();
  }

  handleCloseModalNew() {
    this.setState({ showModalNew: false });
    this.props.onResetModal();
  }
  handleCloseModalSpinnerNew() {
    this.setState({ showModalSpinner: false });
  }
  handleOpenModalSpinnerNew() {
    this.setState({ showModalSpinner: true });
  }
  openAnalyzemodal() {
    this.setState({ showModal: false });
    this.setState({ showModalNew: true });
  }
  deleteVerify(e) {
    if (window.confirm("Do you want to delete this Dataset?")) {
      console.log("remove all");

      var userid = this.state.userid;
      var databaseid = this.state.clickdata;
      const data = {
        userid: userid,
        databaseid: databaseid,
      };
      // console.log(data)
      axios
        .get(
          apiUrl +
            "api/common/deletedataset?userid=" +
            userid +
            "&databaseid=" +
            databaseid
        )
        .then((res) => {
          axios
            .get(
              apiUrl +
                "api/common/getLatestDatabaseId?userid=" +
                this.state.userid
            )
            .then((res) => {
              var latestDatabaseid = res.data;
              axios
                .get(
                  apiUrl +
                    "api/patients/getLatestDatabaseId?userid=" +
                    userid +
                    "&latestDatabaseid=" +
                    latestDatabaseid
                )
                .then((res) => {
                  console.log("Removed");
                  this.handleCloseModal();
                  window.location.href =
                    "/verifier-chart/" + userid + "/" + latestDatabaseid;
                })
                .catch((err) => {
                  //      console.log(err);
                });
            })
            .catch((err) => {
              //        console.log(err);
            });
        })
        .catch((err) => {
          //      console.log(err);
        });
    }
  }
  analyzeDataset(e) {
    var userid = this.state.userid;
    var databaseid = this.state.clickdata;
    if (e.currentTarget.id == "ai") {
      //  console.log("Analyse last");
      //   console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/ai_codes/analyze_last_correct.py";
    } else if (e.currentTarget.id == "hybrid") {
      // console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/classic_peak/analyze_last_correct.py";
    } else if (e.currentTarget.id == "classical") {
      //   console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/classical_code/analyze_last_correct.py";
    } else if (e.currentTarget.id == "temp1") {
      //    console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/ai_codes/classic_negpeak/analyze_last_correct.py";
    } else if (e.currentTarget.id == "temp2") {
      //    console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/pvc_model/analyze_last_correct.py";
    } else if (e.currentTarget.id == "inversion") {
      console.log("Inversion single data");
      console.log("databaseid !!!!!!!!!!!!!!!!", databaseid);
      window.analyzeLink = "/home/ubuntu/web_analysis/single_invert.py";
    }
    //  console.log('window.analyzeLink',window.analyzeLink);
    // console.log('uid',userid);
    //  console.log('uid',databaseid);
    // console.log('&&databaseid=');
    if (window.analyzeLink != "") {
      var userid = this.state.userid;
      var databaseid = this.state.clickdata;
      e.currentTarget.innerHTML = "Analyzing...";
      //const userid = this.props.match.params.userid;
      this.handleCloseModalNew();
      this.handleOpenModalSpinnerNew();
      axios
        .get(
          "https://web.mybiocalculus.com/html/welcome/analyzeDataset/?userid=" +
            userid +
            "&&databaseid=" +
            databaseid +
            "&&url=" +
            window.analyzeLink
        )
        .then((res) => {
          console.log("database analys");
          console.log(
            "https://web.mybiocalculus.com/html/welcome/analyzeDataset/?userid=" +
              userid +
              "&&databaseid=" +
              databaseid +
              "&&url=" +
              window.analyzeLink
          );
          console.log(res.data);
          console.log(window.analyzeLink);
          //window.location.href = '/';
          this.handleCloseModalSpinnerNew();
        })
        .catch((err) => {
          //     console.log(err);
          this.handleCloseModalSpinnerNew();
        });
    }
  }
  /* _onSelect(e)
  {
  
    if(e.value=='RPeaks')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/rpeaks_codes/analyze_last_correct.py';
    }
    else if(e.value=='Classic')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_peak/analyze_last_correct.py';
    }
    else if(e.value=='Classical')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classical_code/analyze_last_correct.py';
    }
    else if(e.value=='Classic Negative')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/classic_negpeak/analyze_last_correct.py';
    }
    else if(e.value=='AI')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/ai_codes/analyze_last_correct.py';
    }
    else if(e.value=='AIcodes')
    {
        console.log('Select',e.value);
        window.analyzeLink='/home/biocalculusssh/web_analysis/saroja_aicodes/analyze_last_correct.py';
    }
   
  }
*/
  render() {
    /* const imageList = [{
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
},{
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}, {
  src: '../../dist/img/AI.png',
  width: 152,
  height: 102
}]; */
    const customStyles = {
      content: {
        background: "transparent",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    const customStylesNew = {
      content: {
        top: "38%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        border: "1px solid rgb(204, 204, 204)",
        background: "rgb(255, 255, 255)",
        overflow: "auto",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        height: "45%",
        width: "35%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    //  const options = [
    //        'RPeaks', 'Classic', 'Classical','Classic Negative', 'AI','AIcodes'
    //       ];
    //        const defaultOption = options[0];
    // console.log(this.state.showModal)
    return (
      <React.Fragment>
        <ReactModal isOpen={this.state.showModalSpinner} style={customStyles}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          <span style={{color:"black"}}>Analyzing...</span>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <React.Fragment>
            <div className="blog-pop-bg"></div>
            <div className="blog-pop-box">
              <button
                className="pull-right closeBtn"
                onClick={this.handleCloseModal}
              >
                &#10006;
              </button>

              <div className="fea pull-left" style={{ marginRight: "50px" }}>
                {this.state.startProp == undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.openAnalyzemodal.bind(this)}
                      src="../../dist/img/blog-pop1.png"
                      alt=""
                    />
                  </button>
                )}
                {this.state.startProp != undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.openAnalyzemodal.bind(this)}
                      src="../../../../../dist/img/blog-pop1.png"
                      alt=""
                    />
                  </button>
                )}
                <p style={{ marginLeft: "30%", fontSize: "25px" }}>Analyze</p>
              </div>
              <div className="fea pull-right" style={{ marginLeft: "50px" }}>
                {this.state.startProp == undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.deleteVerify.bind(this)}
                      src="../../dist/img/blog-pop2.png"
                      alt=""
                    />
                  </button>
                )}
                {this.state.startProp != undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.deleteVerify.bind(this)}
                      src="../../../../../dist/img/blog-pop2.png"
                      alt=""
                    />
                  </button>
                )}
                <p style={{ marginLeft: "40%", fontSize: "25px" }}>DELETE</p>
              </div>
              <br />
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.showModalNew}
          style={customStylesNew}
          contentLabel="Analyze Modal"
        >
          <React.Fragment>
            <div className="blog-pop-box">
              <button
                className="pull-right closeBtn"
                onClick={this.handleCloseModalNew}
              >
                &#10006;
              </button>

              <div className="container col-md-12">
                <div className="row" style={{ marginTop: "15%" }}>
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="ai"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/AI.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="classical"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/CLASSICAL.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="hybrid"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/HYBRID.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="temp1"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/TEMPLATE1.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="temp2"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/TEMPLATE2.png"
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="inversion"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/inversion.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </ReactModal>
      </React.Fragment>
    );
  }
}

export default AddModal;
